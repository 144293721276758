import { PointOfInterest } from "../../../models/domain/poi/PointOfInterest";
import * as mapboxgl from "mapbox-gl";
import { Injectable, SimpleChanges } from "@angular/core";
import * as turf from "@turf/turf";
import { PoiInclusionExclusion } from "src/app/core/models/domain/poi/PoiInclusionExclusion";
import { LAYER_COLORS } from "../map-view-constants";
import { toggleLayer } from "./map-box-layers-utils";
import { pollSource } from "./mapbox-utils";
@Injectable({
  providedIn: "root",
})
export class POILayersManager {
  private POI_INCLUSION_CIRCLE_LAYER_ID = "poiInclusionCircles";
  private POI_INCLUSION_CIRCLE_STROKE_LAYER_ID = "poiInclusionCirclesStroke";
  private POI_INCLUSION_GEO_AREA_SOURCE_ID = "poiInclusionCircleSource";

  private POI_INCLUSION_SOURCE_ID = "poiInclusionSource";
  private POI_INCLUSION_ICON_LAYER_ID = "poiInclusionIcons";

  private POI_EXCLUSION_CIRCLE_LAYER_ID = "poiExclusionCircles";
  private POI_EXCLUSION_CIRCLE_STROKE_LAYER_ID = "poiExclusionCirclesStroke";
  private POI_EXCLUSION_GEO_AREA_SOURCE_ID = "poiExclusionCircleSource";

  private POI_EXCLUSION_SOURCE_ID = "poiExclusionSource";
  private POI_EXCLUSION_ICON_LAYER_ID = "poiExclusionIcons";
  private POI_ICON_LAYER_MIN_ZOOM = 0;

  private customPoiMarkers = [];
  private standardPoiMarkers = [];

  private customPoiInclusionBoundaries = [];
  private standardPoiInclusionBoundaries = [];

  private customPoiExclusionBoundaries = [];
  private standardPoiExclusionBoundaries = [];

  private customPoiInclusionPois = [];
  private standardPoiInclusionPois = [];

  private customPoiExclusionPois = [];
  private standardPoiExclusionPois = [];

  public setupPOILayersData(map: mapboxgl.Map) {
    this.setupPOIGeoAreaSources(map);
    this.setupPOISources(map);
  }

  private getLayerPropertiesFromPoiData(poiData: PoiInclusionExclusion) {
    const inclusionBoundaries = [];
    const inclusionPois = [];
    const poisWithMarkers = [];

    poiData.inclusivePOIs.forEach((poi) => {
      inclusionBoundaries.push(this.convertToPOIBoundaryGeoJSON(poi, poi.radiusInMeters));
      if (this.getIconUrl(poi)) {
        poisWithMarkers.push(poi);
      } else {
        inclusionPois.push(this.convertToGeoJSON(poi));
      }
    });

    const exclusionBoundaries = [];
    const exclusionPois = [];

    poiData.exclusivePOIs.forEach((f) => {
      exclusionBoundaries.push(this.convertToPOIBoundaryGeoJSON(f, f.radiusInMeters));
      if (this.getIconUrl(f)) {
        poisWithMarkers.push(f);
      } else {
        exclusionPois.push(this.convertToGeoJSON(f));
      }
    });

    return { inclusionBoundaries, inclusionPois, exclusionBoundaries, exclusionPois, poisWithMarkers };
  }

  private getIconUrl(poi: PointOfInterest): string {
    return poi?.iconUrl || (poi?.brands && poi?.brands[0]?.logoUrl);
  }

  public updateCustomPOILayers(poiData: PoiInclusionExclusion, map: mapboxgl.Map) {
    const { inclusionBoundaries, inclusionPois, exclusionBoundaries, exclusionPois, poisWithMarkers } =
      this.getLayerPropertiesFromPoiData(poiData);

    this.resetPOIMarkers(this.customPoiMarkers);
    this.customPoiMarkers = poisWithMarkers.map((poi) => this.addPOIMarker(map, poi));

    this.customPoiInclusionBoundaries = inclusionBoundaries;
    this.customPoiInclusionPois = inclusionPois;
    this.customPoiExclusionBoundaries = exclusionBoundaries;
    this.customPoiExclusionPois = exclusionPois;

    this.updatePOIGeoAreaSources(map);
    this.updatePOISources(map);
    map.on("zoom", () => {
      this.scaleMarkersBasedOnZoomLevel([...this.standardPoiMarkers, ...this.customPoiMarkers], map.getZoom());
    });
  }

  public updateStandardPOILayers(poiData: PoiInclusionExclusion, map: mapboxgl.Map) {
    const { inclusionBoundaries, inclusionPois, exclusionBoundaries, exclusionPois, poisWithMarkers } =
      this.getLayerPropertiesFromPoiData(poiData);

    this.resetPOIMarkers(this.standardPoiMarkers);
    this.standardPoiMarkers = poisWithMarkers.map((poi) => this.addPOIMarker(map, poi));

    this.standardPoiInclusionBoundaries = inclusionBoundaries;
    this.standardPoiInclusionPois = inclusionPois;
    this.standardPoiExclusionBoundaries = exclusionBoundaries;
    this.standardPoiExclusionPois = exclusionPois;

    this.updatePOIGeoAreaSources(map);
    this.updatePOISources(map);
    map.on("zoom", () => {
      this.scaleMarkersBasedOnZoomLevel([...this.standardPoiMarkers, ...this.customPoiMarkers], map.getZoom());
    });
  }

  private scaleMarkersBasedOnZoomLevel(markers: mapboxgl.Marker[], zoomLevel: number) {
    const size = this.getSizeBasedOnScale(zoomLevel);
    markers.forEach((marker) => {
      const svgElement = marker.getElement();
      svgElement.style.height = `${size}px`;
      svgElement.style.maxWidth = `${size}px`;
    });
  }

  private getSizeBasedOnScale(scale: number): number {
    switch (Math.floor(scale)) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        return 10;
      case 8:
      case 9:
      case 10:
        return 20;
      case 11:
        return 30;
      case 12:
        return 40;
      case 13:
        return 50;
      case 14:
        return 52;
      case 15:
        return 54;
      case 16:
        return 56;
      case 17:
        return 58;
      case 18:
        return 60;
      case 19:
        return 62;
      case 20:
        return 64;
      case 21:
        return 66;
      case 22:
        return 68;
      case 23:
        return 70;
      default:
        return 72;
    }
  }

  public addPOIInclusionCircleLayer(map: mapboxgl.Map) {
    map.addLayer({
      id: this.POI_INCLUSION_CIRCLE_LAYER_ID,
      type: "fill",
      source: this.POI_INCLUSION_GEO_AREA_SOURCE_ID,
      paint: {
        "fill-color": LAYER_COLORS.POI_INCLUSION_CIRCLE,
        "fill-opacity": 0.5,
      },
      minzoom: this.POI_ICON_LAYER_MIN_ZOOM,
    });
  }

  public addPOIExclusionCircleLayer(map: mapboxgl.Map) {
    map.addLayer({
      id: this.POI_EXCLUSION_CIRCLE_LAYER_ID,
      type: "fill",
      source: this.POI_EXCLUSION_GEO_AREA_SOURCE_ID,
      paint: {
        "fill-color": LAYER_COLORS.POI_EXCLUSION_CIRCLE,
        "fill-opacity": 0.5,
      },
      minzoom: this.POI_ICON_LAYER_MIN_ZOOM,
    });
  }

  public addPOIInclusionCircleStrokeLayer(map: mapboxgl.Map) {
    map.addLayer({
      id: this.POI_INCLUSION_CIRCLE_STROKE_LAYER_ID,
      type: "line",
      source: this.POI_INCLUSION_GEO_AREA_SOURCE_ID,
      paint: {
        "line-color": LAYER_COLORS.POI_INCLUSION_CIRCLE_STROKE,
        "line-width": 1,
      },
      minzoom: this.POI_ICON_LAYER_MIN_ZOOM,
    });
  }

  addPOIExclusionCircleStrokeLayer(map: mapboxgl.Map) {
    map.addLayer({
      id: this.POI_EXCLUSION_CIRCLE_STROKE_LAYER_ID,
      type: "line",
      source: this.POI_EXCLUSION_GEO_AREA_SOURCE_ID,
      paint: {
        "line-color": LAYER_COLORS.POI_EXCLUSION_CIRCLE_STROKE,
        "line-width": 1,
      },
      minzoom: this.POI_ICON_LAYER_MIN_ZOOM,
    });
  }

  public addPOIInclusionIconLayer(map: mapboxgl.Map, poiData: SimpleChanges) {
    map.addLayer({
      id: this.POI_INCLUSION_ICON_LAYER_ID,
      type: "symbol",
      source: this.POI_INCLUSION_SOURCE_ID,
      layout: {
        "icon-image": "Text_holder",
        "icon-anchor": "bottom",

        "icon-allow-overlap": ["step", ["zoom"], false, 14, true],
        "text-allow-overlap": true,
        "text-ignore-placement": true,
        "text-optional": true,

        "text-anchor": "bottom",
        "text-field": ["get", "displayName"],
        "icon-text-fit": "width",
        "icon-text-fit-padding": [2, 6, 10, 6],
        "text-size": 10,
        "text-justify": "center",
      },
      paint: {
        "text-color": "#136691",
      },
      minzoom: this.POI_ICON_LAYER_MIN_ZOOM,
    });
    map.on("click", this.POI_INCLUSION_ICON_LAYER_ID, (event) => {
      this.displayPOIPopUp(event, map);
    });
  }

  private displayPOIPopUp(event: mapboxgl.MapMouseEvent, map: mapboxgl.Map) {
    const coordinates = event.features[0].geometry.coordinates.slice();
    const name = `${event.features[0].properties.brand}, ${event.features[0].properties.name}`;
    const address = `${event.features[0].properties.address}`;
    const postalCode = event.features[0].properties.postalCode;
    const lat = coordinates[0].toFixed(6);
    const lng = coordinates[1].toFixed(6);
    const description = this.getDetailsForPopUp(name, address, postalCode, lat, lng);

    new mapboxgl.Popup().setMaxWidth("600px").setLngLat([lat, lng]).setHTML(description).addTo(map);
  }

  private createPOIPopUp(poi: PointOfInterest): mapboxgl.Popup {
    const name = `${poi?.brand}, ${poi?.name}`;
    const address = `${poi?.address}`;
    const postalCode = poi?.postalCode;
    const lat = poi?.geoLocation?.coordinates[1];
    const lng = poi?.geoLocation?.coordinates[0];

    return new mapboxgl.Popup()
      .setMaxWidth("600px")
      .setHTML(this.getDetailsForPopUp(name, address, postalCode, lat, lng));
  }

  private getDetailsForPopUp(name: string, address: string, postalCode: string, lat: number, lng: number) {
    return `<div class="poi-popup-details">
     <div class="title">Name</div>
    <div class="data">
      ${name}
    </div>
    <div class="title">Address</div>
    <div class="data">
      ${address}
    </div>

    <div class="title">ZipCode</div>
    <div class="data">
      ${postalCode}
    </div>

    <div class="latLng-info">
      <div class="title">Lat-Lng</div>
      <div class="data">
        ${lat},
        ${lng}
      </div>

      <a class="street-view" href="https://maps.google.com/?cbll=${lat},${lng}&cbp=12,20.09,,0,5&layer=c" target="_blank">
        <img
          src="../../../../assets/images/street_view.svg"
          alt=""
          class="img-20"/>
      </a>
    </div>
  </div>
  `;
  }

  public addPOIExclusionIconLayer(map: mapboxgl.Map) {
    map.addLayer({
      id: this.POI_EXCLUSION_ICON_LAYER_ID,
      type: "symbol",
      source: this.POI_EXCLUSION_SOURCE_ID,
      layout: {
        "icon-image": "Text_holder",
        "icon-anchor": "bottom",

        "icon-allow-overlap": ["step", ["zoom"], false, 14, true],
        "text-allow-overlap": true,
        "text-ignore-placement": true,
        "text-optional": true,

        "text-anchor": "bottom",
        "text-field": ["get", "displayName"],
        "icon-text-fit": "width",
        "icon-text-fit-padding": [2, 6, 10, 6],
        "text-size": 10,
        "text-justify": "center",
      },
      paint: {
        "text-color": "#136691",
      },
      minzoom: this.POI_ICON_LAYER_MIN_ZOOM,
    });
    map.on("click", this.POI_EXCLUSION_ICON_LAYER_ID, (poi) => {
      this.displayPOIPopUp(poi, map);
    });
  }

  public resetPOISources(map: mapboxgl.Map) {
    this.customPoiInclusionBoundaries = [];
    this.standardPoiInclusionBoundaries = [];
    this.customPoiExclusionBoundaries = [];
    this.standardPoiExclusionBoundaries = [];
    this.customPoiInclusionPois = [];
    this.standardPoiInclusionPois = [];
    this.customPoiExclusionPois = [];
    this.standardPoiExclusionPois = [];
    this.resetPOIMarkers(this.customPoiMarkers);
    this.resetPOIMarkers(this.standardPoiMarkers);
    this.resetMapSource(map, this.POI_INCLUSION_SOURCE_ID);
    this.resetMapSource(map, this.POI_EXCLUSION_SOURCE_ID);
    this.resetMapSource(map, this.POI_INCLUSION_GEO_AREA_SOURCE_ID);
    this.resetMapSource(map, this.POI_EXCLUSION_GEO_AREA_SOURCE_ID);
  }

  public togglePOICircles(map: mapboxgl.Map) {
    [
      this.POI_INCLUSION_CIRCLE_LAYER_ID,
      this.POI_INCLUSION_CIRCLE_STROKE_LAYER_ID,
      this.POI_EXCLUSION_CIRCLE_LAYER_ID,
      this.POI_EXCLUSION_CIRCLE_STROKE_LAYER_ID,
    ].map((layer) => toggleLayer(layer, map));
  }

  private updatePOIGeoAreaSources(map: mapboxgl.Map) {
    this.setSourceData(map, this.POI_INCLUSION_GEO_AREA_SOURCE_ID, {
      type: "FeatureCollection",
      features: [...this.customPoiInclusionBoundaries, ...this.standardPoiInclusionBoundaries],
    });

    this.setSourceData(map, this.POI_EXCLUSION_GEO_AREA_SOURCE_ID, {
      type: "FeatureCollection",
      features: [...this.customPoiExclusionBoundaries, ...this.standardPoiExclusionBoundaries],
    });
  }

  private updatePOIInclusionSource(map: mapboxgl.Map) {
    this.setSourceData(map, this.POI_INCLUSION_SOURCE_ID, {
      type: "FeatureCollection",
      features: [...this.customPoiInclusionPois, ...this.standardPoiInclusionPois],
    });
  }

  private updatePOIExclusionSource(map: mapboxgl.Map) {
    this.setSourceData(map, this.POI_EXCLUSION_SOURCE_ID, {
      type: "FeatureCollection",
      features: [...this.customPoiExclusionPois, ...this.standardPoiExclusionPois],
    });
  }

  private convertToPOIBoundaryGeoJSON(poi: PointOfInterest, poiRadiusInMeters: number) {
    return turf.circle(poi.geoLocation.coordinates, poiRadiusInMeters, {
      steps: 50,
      units: "meters",
      properties: {
        name: poi.name,
        brand: poi.brand || poi?.brands?.[0]?.name,
        iconUrl: poi.iconUrl || poi?.brands?.[0]?.logoUrl,
        postalCode: poi.postalCode,
      },
    });
  }

  private convertToGeoJSON(poi: PointOfInterest) {
    return {
      type: "Feature",
      properties: {
        name: poi.name,
        address: poi?.address,
        brand: poi.brand || poi?.brands?.[0]?.name,
        iconUrl: poi.iconUrl || poi?.brands?.[0]?.logoUrl,
        postalCode: poi.postalCode,
        displayName: this.abbreviatePOIDisplayName(poi.name || poi.postalCode || poi.brand).toUpperCase(),
      },
      geometry: { type: "Point", coordinates: poi.geoLocation.coordinates },
    };
  }

  private setupPOIGeoAreaSources(map: mapboxgl.Map) {
    this.customPoiInclusionBoundaries = [];
    this.standardPoiInclusionBoundaries = [];
    map.addSource(this.POI_INCLUSION_GEO_AREA_SOURCE_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    this.customPoiExclusionBoundaries = [];
    this.standardPoiExclusionBoundaries = [];
    map.addSource(this.POI_EXCLUSION_GEO_AREA_SOURCE_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
  }

  private setupPOIInclusionSource(map: mapboxgl.Map) {
    this.customPoiInclusionPois = [];
    this.standardPoiInclusionPois = [];
    map.addSource(this.POI_INCLUSION_SOURCE_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
  }

  private setupPOIExclusionSource(map: mapboxgl.Map) {
    this.customPoiExclusionPois = [];
    this.standardPoiExclusionPois = [];
    map.addSource(this.POI_EXCLUSION_SOURCE_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
  }

  private addPOIMarker(map: mapboxgl.Map, poi: PointOfInterest): mapboxgl.Marker {
    const el = document.createElement("img");
    el.className = "poi-marker";
    el.setAttribute("src", this.getIconUrl(poi));
    const popUp = this.createPOIPopUp(poi);

    return new mapboxgl.Marker(el).setLngLat(poi.geoLocation.coordinates).setPopup(popUp).addTo(map);
  }

  private loadPOICustomImageForMap(map: mapboxgl.Map, brandName: string, customImage: string) {
    map.loadImage(customImage, (error, image) => {
      if (error) {
        throw error;
      }
      map.addImage(brandName, image);
    });
  }
  private abbreviatePOIDisplayName(displayName) {
    if (displayName.length > 9) {
      return displayName.substring(0, 9) + "...";
    } else {
      return displayName;
    }
  }

  private resetMapSource(map: mapboxgl.Map, sourceId: string) {
    map.getSource(sourceId).setData({
      type: "FeatureCollection",
      features: [],
    });
  }

  private resetPOIMarkers(poiMarkers: mapboxgl.Marker[]) {
    poiMarkers.forEach((m) => m.remove());
    poiMarkers = [];
  }

  private setupPOISources(map: mapboxgl.Map) {
    this.setupPOIInclusionSource(map);
    this.setupPOIExclusionSource(map);
  }

  private updatePOISources(map: any) {
    this.updatePOIInclusionSource(map);
    this.updatePOIExclusionSource(map);
  }

  private setSourceData(map: mapboxgl.Map, sourceId: string, data: any) {
    pollSource(map, sourceId)
      .then(() => map.getSource(sourceId).setData(data))
      .catch((err) => console.error(`Failed to add data to layer: ${sourceId} reason: ${err}`));
  }
}
