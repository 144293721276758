import { Frame } from "../../models/domain/Frame";
import { MapBoxLegendManager } from "./mapbox-controls/mapbox-legends-manager";
import { MapBoxControlsManager, MapControlsConfig } from "./mapbox-controls/mapbox-controls-manager";
import { MapBoxfactory } from "./MapBoxFactory";
import { Injectable, SimpleChanges } from "@angular/core";
import * as mapboxgl from "mapbox-gl";
import { FrameLayersManager } from "./mapbox-layers/frame-layers-manager";
import { DmaBoundaryLayersManager } from "./mapbox-layers/dma-boundary-layers-manager";
import { CountyBoundaryLayersManager } from "./mapbox-layers/county-boundary-layers-manager";
import { POILayersManager } from "./mapbox-layers/poi-layer-manager";
import { MapboxBoundaryLayersManager } from "./mapbox-layers/mapbox-boundary-layer-manager";
import { PoiInclusionExclusion } from "src/app/core/models/domain/poi/PoiInclusionExclusion";
import { FeatureToggleService } from "src/app/core/svc/resource-svc/featureToggle/featureToggle.service";
import { AssociatedPOIRequestManager } from "./mapbox-layers/associated-poi-request-manager";
import { POIPopupDialogManager } from "./mapbox-layers/poi-popup-dialog-manager";
import { FrameLegendBy } from "plato-map-lib";
import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { AudienceIndexLayerManager } from "./mapbox-layers/audience-index-layer-manager";
import { AudienceIndexHeatmapLayerManager } from "./mapbox-layers/audience-index-heatmap-layer-manager";
import { LocationLayersManager } from "./mapbox-layers/location-layers-manager";

const environment = new ConfigurationService().getConfig();

@Injectable({
  providedIn: "root",
})
export class MapBoxManager {
  private map: mapboxgl.Map;
  private maxMarkers: number;
  private currentBounds: number[][];

  private updateFrameLegendDataCallback = (a: any) => {};
  private updatePoiLegendDataCallback = (a: any) => {};
  private updateFrameDetailsPopUpCallback = (a: any) => {};
  private searchWithDrawnShapesCallback: any = (a: any) => {};
  private resetSelectedFramesCallback = () => {};
  private setSupportedFilterCallback = (a: any) => {};
  private toggleSummary: any;
  private customInclusivePOIData = [];
  private standardInclusivePOIData = [];
  private frameLegendSelected = FrameLegendBy.Format;
  private poiData: SimpleChanges = null;
  private showLocationLayer: boolean = false;
  private mapLoaded = false;
  private mapControlsConfig: MapControlsConfig;
  private _showAudienceLayers = true;

  public set showAudienceLayers(value: boolean) {
    this._showAudienceLayers = value;
  }

  constructor(
    private mapControlsManager: MapBoxControlsManager,
    private featureToggleService: FeatureToggleService,
    private mapLegendManager: MapBoxLegendManager,
    private mapboxFactory: MapBoxfactory,
    private frameLayersManager: FrameLayersManager,
    private locationsLayersManager: LocationLayersManager,
    private poiLayersManager: POILayersManager,
    private audienceIndexLayerManager: AudienceIndexLayerManager,
    private audienceIndexHeatmapLayerManager: AudienceIndexHeatmapLayerManager,
    private associatedPOIRequestManager: AssociatedPOIRequestManager,
    private poiPopupDialogManager: POIPopupDialogManager,
    private dmaBoundaryLayersManager: DmaBoundaryLayersManager,
    private mapboxBoundaryLayersManager: MapboxBoundaryLayersManager,
    private countyBoundaryLayersManager: CountyBoundaryLayersManager,
    private configurationService: ConfigurationService
  ) {}

  public initMap(
    container: string,
    centerCoordinates: number[],
    defaultZoom: number,
    mapControlsConfig: MapControlsConfig = {}
  ) {
    this.map = this.mapboxFactory.create(container, centerCoordinates, defaultZoom);
    this.mapControlsConfig = mapControlsConfig;
    this.showLocationLayer = this.configurationService.getConfig()?.mapConfig?.showLocationLayer;
    this.setUpMap();
  }

  public setCallbacks(
    updateLegendDataCallback: any,
    updatePoiLegendDataCallback: any,
    updateFrameDetailsPopUpCallback: any,
    searchWithDrawnShapesCallback: any,
    resetSelectedFramesCallback: any,
    setFormatTypesCallback: any,
    toggleSummary: any
  ) {
    this.updateFrameLegendDataCallback = updateLegendDataCallback;
    this.updatePoiLegendDataCallback = updatePoiLegendDataCallback;
    this.updateFrameDetailsPopUpCallback = updateFrameDetailsPopUpCallback;
    this.searchWithDrawnShapesCallback = searchWithDrawnShapesCallback;
    this.resetSelectedFramesCallback = resetSelectedFramesCallback;
    this.setSupportedFilterCallback = setFormatTypesCallback;
    this.toggleSummary = toggleSummary;
  }

  public onMapDataRecieved(data: SimpleChanges, selectedFrameIds: string[]) {
    //   if(selectedFrameIds && selectedFrameIds.length> 0) {
    //   alert('selected frame'+ selectedFrameIds);
    // }
    if (!this.map || !this.mapLoaded) {
      setTimeout(() => {
        this.onMapDataRecieved(data, selectedFrameIds);
      }, 100);
      return;
    }

    this.poiData = data;
    if (data.frames) {
      const newFrames = data.frames?.currentValue || [];
      this.updateFrameLayersAndLegendOnNewData(newFrames, selectedFrameIds);
      this.poiPopupDialogManager.updateLatestFrames(newFrames);
    }

    const inclusionExclusionData = data?.inclusionExclusionData?.currentValue;
    if (inclusionExclusionData?.inclusivePOIs || inclusionExclusionData?.exclusivePOIs) {
      this.updateStandardPOILayersAndLegendOnNewData(data.inclusionExclusionData.currentValue);
    }

    const customPoiInclusionExclusionData = data?.customPoiInclusionExclusionData?.currentValue;
    if (customPoiInclusionExclusionData?.inclusivePOIs || customPoiInclusionExclusionData?.exclusivePOIs) {
      this.updateCustomPOILayersAndLegendOnNewData(data.customPoiInclusionExclusionData.currentValue);
    }

    if (data.selectedFrames?.currentValue) {
      if (data.selectedFrames.currentValue.length > 0) {
        this.updateFrameLayersAndLegendOnFrameSelection(data.selectedFrames.currentValue, selectedFrameIds);
        this.poiPopupDialogManager.updateLatestFrames(data.selectedFrames.currentValue);
      } else {
        this.updateFrameLayersOnShowAllFrames(selectedFrameIds);
      }
    }

    if (data.maxMarkers?.currentValue) {
      this.maxMarkers = data.maxMarkers.currentValue;
    }
    if (environment.mapConfig.updateDMAAndCountyBoundaries && data.geoLocationSearchCriterion?.currentValue) {
      this.dmaBoundaryLayersManager.updateSelectedDmas(this.map, data.geoLocationSearchCriterion.currentValue.tvRegion);
      this.countyBoundaryLayersManager.updateSelectedCounties(
        this.map,
        data.geoLocationSearchCriterion.currentValue.county
      );
    }

    this.panMapViewToFitAllFrames();
  }

  public panMapViewToFitAllFrames() {
    const bounds = this.frameLayersManager.getMapBoundsForAllFrames();
    if (this.frameLayersManager.frameData?.length > 0) {
      if (!this.areEqualBounds(bounds, this.currentBounds)) {
        this.map.fitBounds(bounds, {
          maxZoom: 13,
          padding: { top: 80, bottom: 80, left: 80, right: 80 },
        });
        this.currentBounds = bounds;
      }
    }
  }

  public resetDraw() {
    this.mapControlsManager.deleteAllDrawings();
  }

  public applyHighlightedBorderToFrameLayers(selectedFrameIds: string[]) {
    this.frameLayersManager.applyHighlightedBorderToFrameLayer(selectedFrameIds);
  }

  public applyLegendColorToFrameLayers(frames: Frame[], selectedFrameIds: string[], frameLegendBy: FrameLegendBy) {
    this.frameLegendSelected = frameLegendBy;
    const [frameLegendFormatColorCodeMapping, frameLegendMOColorCodeMapping, supportedFilters] =
      this.mapLegendManager.getAllColorCodesAndFilters(frames, this.maxMarkers);
    const frameColorMap =
      this.frameLegendSelected == FrameLegendBy.Format
        ? frameLegendFormatColorCodeMapping
        : frameLegendMOColorCodeMapping;
    this.frameLayersManager.applyLegendColorToFrameLayers(frameColorMap, selectedFrameIds, this.frameLegendSelected);
    this.locationsLayersManager.applyLegendColorToLocationLayers(
      frameColorMap,
      selectedFrameIds,
      this.frameLegendSelected
    );
    this.updateFrameLegendDataCallback(frameColorMap);
  }

  public resetMapFrameSource() {
    this.frameLayersManager.resetFrameSource();
    this.locationsLayersManager.resetLocationSource();
    this.resetSelectedFrameFilters();
  }

  public resetSelectedFrameFilters() {
    this.frameLayersManager.resetSelectedFrameFilters();
    this.locationsLayersManager.resetSelectedFilters();
    this.resetSelectedFramesCallback();
  }

  public resetMapPOISource() {
    this.customInclusivePOIData = [];
    this.standardInclusivePOIData = [];
    this.poiLayersManager.resetPOISources(this.map);
  }

  public resetDMABoundaries() {
    this.dmaBoundaryLayersManager.resetDMAFilters(this.map);
  }

  public resetCountyBoundaries() {
    this.countyBoundaryLayersManager.resetCountyFilters(this.map);
  }

  public applyFiltersOnAllFrames(allFilters) {
    this.frameLayersManager.applyFilterOnAllFrames(allFilters);
    this.locationsLayersManager.applyFilterOnAllLocations(allFilters);
  }

  public applyFilterOnSelectedFrames(selectedFrames: Frame[], allFilters) {
    this.frameLayersManager.applyFilterOnSelectedFrames(selectedFrames, allFilters);
  }

  togglePOICircles() {
    this.poiLayersManager.togglePOICircles(this.map);
  }

  toggleAudienceIndex() {
    this.audienceIndexLayerManager.toggleAudienceIndex(this.map);
    this.audienceIndexHeatmapLayerManager.toggleAudienceIndex(this.map);
  }

  toggleDmaBoundaries() {
    this.dmaBoundaryLayersManager.toggleAllDmaBoundariesUSvisibility(this.map);
  }

  toggleCountyBoundaries() {
    this.countyBoundaryLayersManager.toggleAllCountyBoundariesVisibility(this.map);
  }

  public togglePostalCodeBoundaries(): boolean {
    if (this.mapboxBoundaryLayersManager.isVisible) {
      this.resetMapboxBoundaryFilter();
    } else {
      this.showMapboxBoundaryFilter();
    }
    return this.mapboxBoundaryLayersManager.isVisible;
  }

  private toggleFramesLabel() {
    this.frameLayersManager.toggleFrameLabels();
    this.locationsLayersManager.toggleFrameLabels();
  }

  updateFrameLayersOnShowAllFrames(selectedFrameIds: string[]) {
    const frameColorMap =
      this.frameLegendSelected == FrameLegendBy.Format
        ? this.mapLegendManager.getShowAllFormatTypeColorCodeMappingForLegend()
        : this.mapLegendManager.getShowAllMediaOwnerColorCodeMappingForLegend();
    this.updateFrameLegendDataCallback(frameColorMap);
    this.resetSelectedFramesCallback();
    this.updateFrameLayersOnShowAll(frameColorMap, selectedFrameIds);
    this.setSupportedFilterCallback(this.mapLegendManager.getShowAllFramesSupportedFilter());
  }

  updateFrameLayersAndLegendOnFrameSelection(selectedFrames: Frame[], selectedFrameIds: string[]) {
    const [colorCodeMapping, supportedFilters] = this.mapLegendManager.getColorCodeMappingForSelectedFrames(
      selectedFrames,
      this.maxMarkers,
      this.frameLegendSelected
    );
    this.updateFrameLegendDataCallback(colorCodeMapping);
    this.setSupportedFilterCallback(supportedFilters);
    this.updateFrameLayersOnFrameSelection(selectedFrames, colorCodeMapping, selectedFrameIds);
  }

  private updateFrameLayersOnFrameSelection(
    selectedFrames: Frame[],
    frameLegendColorCodeMapping: {},
    selectedFrameIds: string[]
  ) {
    this.frameLayersManager.applySelectedFramesFilter(selectedFrames);
    this.frameLayersManager.applyLegendColorToFrameLayers(
      frameLegendColorCodeMapping,
      selectedFrameIds,
      this.frameLegendSelected
    );
    this.locationsLayersManager.applyLegendColorToLocationLayers(
      frameLegendColorCodeMapping,
      selectedFrameIds,
      this.frameLegendSelected
    );
  }

  updateFrameLayersOnShowAll(frameLegendColorCodeMapping: {}, selectedFrameIds: string[]) {
    this.frameLayersManager.resetSelectedFrameFilters();
    this.locationsLayersManager.resetSelectedFilters();
    this.locationsLayersManager.applyLegendColorToLocationLayers(
      frameLegendColorCodeMapping,
      selectedFrameIds,
      this.frameLegendSelected
    );
    this.frameLayersManager.applyLegendColorToFrameLayers(
      frameLegendColorCodeMapping,
      selectedFrameIds,
      this.frameLegendSelected
    );
  }

  updateFrameLayersAndLegendOnNewData(frameData: Frame[], selectedFrameIds: string[]) {
    const [frameColorMap, supportedFilters] = this.mapLegendManager.getColorCodesAndFiltersOnNewData(
      frameData,
      this.maxMarkers,
      this.frameLegendSelected
    );
    this.setSupportedFilterCallback(supportedFilters);
    this.updateFrameLegendDataCallback(frameColorMap);
    this.updateFrameLayersOnNewData(frameData, frameColorMap, selectedFrameIds, this.frameLegendSelected);
  }

  updateFrameLayersOnNewData(
    frameData: Frame[],
    colorCodeMapping: {},
    selectedFrameIds: string[],
    frameLegendBy: string
  ) {
    this.frameLayersManager.applyLegendColorToFrameLayers(colorCodeMapping, selectedFrameIds, frameLegendBy);
    this.locationsLayersManager.applyLegendColorToLocationLayers(colorCodeMapping, selectedFrameIds, frameLegendBy);
    this.locationsLayersManager.updateLocationLayers(frameData);
    this.frameLayersManager.updateFrameLayers(frameData);
  }

  updateStandardPOILayersAndLegendOnNewData(poidata: PoiInclusionExclusion) {
    this.standardInclusivePOIData = poidata.inclusivePOIs;
    const poiColorCodeMapping = this.mapLegendManager.getFormatColorCodeMappingForPOIs([
      ...this.customInclusivePOIData,
      ...this.standardInclusivePOIData,
    ]);
    this.updatePoiLegendDataCallback(poiColorCodeMapping);
    this.poiLayersManager.updateStandardPOILayers(poidata, this.map);
  }

  updateCustomPOILayersAndLegendOnNewData(poidata: PoiInclusionExclusion) {
    this.customInclusivePOIData = poidata.inclusivePOIs;
    const poiColorCodeMapping = this.mapLegendManager.getFormatColorCodeMappingForPOIs([
      ...this.customInclusivePOIData,
      ...this.standardInclusivePOIData,
    ]);
    this.updatePoiLegendDataCallback(poiColorCodeMapping);
    this.poiLayersManager.updateCustomPOILayers(poidata, this.map);
  }

  private setUpMap() {
    this.map.on("load", () => this.onMapLoad());
  }

  public onMapLoad() {
    const popUp = this.mapboxFactory.createPopUp(true, true, "336px", "frame-popup");
    this.setUpMapLayers(this.map, popUp, this.updateFrameDetailsPopUpCallback);
    this.mapControlsManager.buildControls(
      this.map,
      this.searchWithDrawnShapesCallback,
      () => this.togglePOICircles(),
      () => this.toggleAudienceIndex(),
      () => this.toggleDmaBoundaries(),
      () => this.toggleCountyBoundaries(),
      () => this.togglePostalCodeBoundaries(),
      () => this.toggleSummary(),
      () => this.toggleFramesLabel(),
      this.mapControlsConfig
    );
    this.map.loadImage("../../../../assets/images/Text_holder.png", (error, image) => {
      if (error) {
        throw error;
      }
      this.map.addImage("Text_holder", image);
    });
    this.mapLoaded = true;
  }

  private setUpMapLayers(map: mapboxgl.Map, popup: mapboxgl.Popup, functionToUpdateFrameDetailsPopUp) {
    this.customInclusivePOIData = [];
    this.standardInclusivePOIData = [];
    this.mapboxBoundaryLayersManager.setupMapboxBoundaryLayers(map);
    this.poiLayersManager.setupPOILayersData(map);

    this.featureToggleService.isFeatureEnabled("ENABLE_ASSOCIATED_POI_MAP").subscribe((data) => {
      data ? this.associatedPOIRequestManager.setUp(map) : "";
      data ? this.poiPopupDialogManager.setUp(map) : "";
    });
    this.frameLayersManager.setUpFramelayersData(map, popup, functionToUpdateFrameDetailsPopUp);

    if (this.showLocationLayer) {
      this.locationsLayersManager.setUpData(map, popup, functionToUpdateFrameDetailsPopUp);
    }

    this.addLayersInOrder(map);
  }

  private addLayersInOrder(map: mapboxgl.Map) {
    this.poiLayersManager.addPOIExclusionCircleLayer(map);
    this.poiLayersManager.addPOIExclusionCircleStrokeLayer(map);
    this.poiLayersManager.addPOIExclusionIconLayer(map);

    this.poiLayersManager.addPOIInclusionCircleLayer(map);
    this.poiLayersManager.addPOIInclusionCircleStrokeLayer(map);

    this.audienceIndexLayerManager.addLayers(map);

    if (this.showLocationLayer) {
      this.locationsLayersManager.addLocationsLayer(map);
      this.locationsLayersManager.addLabelsLayer(map);
    }

    this.frameLayersManager.addFramesLabelLayer(map);
    this.frameLayersManager.addFramesLayer(map);
    this.frameLayersManager.addFramesOuterLayer(map);

    if (environment.mapConfig.updateDMAAndCountyBoundaries) {
      this.frameLayersManager.addUnclusteredPointLayerUS(map);
      this.dmaBoundaryLayersManager.addAllDmaBoundariesUS(map);
      this.countyBoundaryLayersManager.addAllCountyBoundaries(map);
    } else {
      this.frameLayersManager.addUnclusteredPointLayer(map);
    }
    this.poiLayersManager.addPOIInclusionIconLayer(map, this.poiData);

    this.audienceIndexHeatmapLayerManager.addLayers(this.map);
  }

  private areEqualBounds(bounds1: number[][], bounds2: number[][]) {
    if (bounds1 && bounds2) {
      return (
        bounds1[0][0] === bounds2[0][0] &&
        bounds1[0][1] === bounds2[0][1] &&
        bounds1[1][0] === bounds2[1][0] &&
        bounds1[1][1] === bounds2[1][1]
      );
    }
    return false;
  }

  public highlightBoundariesFor(listOfUnitCodes: string[]) {
    this.mapboxBoundaryLayersManager.updateHighlightBoundariesFor(this.map, listOfUnitCodes);
  }

  public resetMapboxBoundaryFilter() {
    this.mapboxBoundaryLayersManager.resetHighlightedFilter(this.map);
  }
  public showMapboxBoundaryFilter() {
    this.mapboxBoundaryLayersManager.showHighlightBoundariesFor(this.map);
  }

  public rerenderPopup() {
    this.frameLayersManager.rerenderPopup();
  }

  public showAudienceIndex(value: boolean) {
    this._showAudienceLayers = value;
    this.mapControlsManager.showAudienceIndexControl(value);
  }
}
