import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CampaignTabs } from "../../../home/campaigns/edit-campaign/edit-campaign.component";
import { ConfigurationService } from "../../svc/configuration-service";
import { getEditCampaignAPIBasePath } from "../../common/utils";

@Component({
  selector: "app-campaign-breadcrumb",
  templateUrl: "./campaign-breadcrumb.component.html",
  styleUrls: ["./campaign-breadcrumb.component.scss"],
})
export class CampaignBreadcrumbComponent {
  @Input() campaign: {
    id: string;
    campaignName: string;
    brandName: string;
  };
  @Input() title: string = "Campaign";
  @Input() showDates: boolean = false;
  @Input() showCampaignId: boolean = false;
  @Input() showBackToCampaign: boolean = false;

  @Output() backToCampaign = new EventEmitter();

  queryParamsForBackNavFromSearchToCampaign = { stepper: CampaignTabs.MEDIA_AND_COSTS };

  constructor(private configurationService: ConfigurationService) {}

  onBackClick() {
    this.backToCampaign.emit();
  }

  getCampaignEditLink(): string {
    const editCampaignBasePath = getEditCampaignAPIBasePath(this.configurationService.getCurrentRegion());
    return `${editCampaignBasePath}/${this.campaign?.id}`;
  }
}
