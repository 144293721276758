import { Distance } from "./../common/Distance";
import { Agency } from "./Agency";
import { Client } from "./Client";

export class Share {
  id: string;
  sharingId: string;
  description?: string;
  from: {
    userName: string;
    userEmail: string;
  };
  campaignId?: string;
  campaignName: string;
  mediaAgency: Agency;
  client: Client;
  frameIds: Array<string>;
  poiData?: POIIdentities;
  boundaryUnitCodes: Array<string>;
  creationDate: string;
  modifiedDate: string;
  showPOICircle?: boolean;
  region: string;
  campaignLineInfos: CampaignLineInfo[];
}

export class CampaignLineInfo {
  campaignLineId: string;
  bookingStatus: string;
  type: string;
  containerName?: string;
  schedule: {
    startDate: Date;
    endDate: Date;
  };
  locationListId?: string;
}

export class POIIdentity {
  referenceNumber: string;
  poiType: string;
  distance: Distance;
  iconUrl?: string;
  brand?: string;
  name?: string;
}

export class CustomPOISetIdentity {
  referenceNumber: string;
  poiType: string;
  distance: Distance;
}

export class POIIdentities {
  inclusions: Array<POIIdentity>;
  exclusions: Array<POIIdentity>;
  customInclusions?: Array<CustomPOISetIdentity>;
  customExclusions?: Array<CustomPOISetIdentity>;
}
