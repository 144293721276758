import { FileDetail } from "./FileDetail";
import { CampaignFrameSchedule } from "./CampaignFrameSchedule";
import { CampaignNonMediaCost } from "./CampaignNonMediaCost";
import { ProhibitionDetails } from "./prohibition/ProhibitionDetails";
import { CampaignKPIVersionPresenter } from "./CampaignKPIVersionPresenter";
import { IAudience } from "../../../home/audience-analysis-tool/models/aat-models";
import { CampaignLinePresenter } from "./CampaignLinePresenter";
import { SAC } from "../../../home/campaigns/edit-campaign/campaign-details/campaign-budget/sac";
import { SellToReportingCurrencyMultiplier } from "./SellToReportingCurrencyMultiplier";
import { MediaOceanReferences } from "./MediaOceanReferences";
import { BriefDetails } from "./PoiDetails";

export class CampaignChartPresenter {
  chartName: string;
  dimensionsAndMetricsInPercentage: {
    [key: string]: number;
  };
  metricCurrency: string;
}

export const CampaignTypes = {
  DOMESTIC: "DOMESTIC",
  INTERNATIONAL: "INTERNATIONAL",
};

export class BaseCampaign {
  id: any;
  campaignName: string;
  startDate: string;
  endDate: string;
  probabilityPercentage?: number;
  team: string;
  leadPlanner?: string;
  leadBuyer?: string;
  storeId?: string;
  agencyCode?: string;
  clientCode: string;
  brandCode: string;
  productCode: string;
  tags: string[];
  primaryAudience: IAudience;
  campaignKPIVersionPresenter: CampaignKPIVersionPresenter;
  adaAudience?: any | null;
  auditor: string;
  barterAgency: string;
  mediaAgencyCode: string;
  hfss: boolean;
  probabilityLock?: boolean;
  prohibitionDetails: ProhibitionDetails;
  principalBeneficiary: String;
  buyingGuarantee: number;
  agencyCommission: number;
  campaignSACPresenter: SAC;
  totalBudgetNet: number;
  productionNetBudget: number;
  mediaBudgetNet: number;
  mediaBudgetGross: number;
  crossCampaignBudget: number;
  customerReferenceType: string;
  clientOrderNumber?: string;
  defaultCustomerReference?: string;
  campaignStatus: string;
  campaignNotes: string;
  version: number;
  barterAgencyCode: string;
  sellToReportingCurrencyMultiplier: SellToReportingCurrencyMultiplier;
  currencyType: string;
  productionNetSpent: number;
  feeCalculationType?: string;
  feeCalculationValue?: number;
  type?: string;
  briefDetails?: BriefDetails;
  expectedRetainedMargin?: number;
}

export class Campaign extends BaseCampaign {
  clientName: string;
  brandName: string;
  productName: string;
  mediaAgency: string;
  mediaAgencyCountry: string;
  contactName: string;
  contactEmail: string;
  primaryAudienceReferenceId: string;
  plannedCover: number;
  plannedFrequency: number;
  plannedImpacts: number;
  plannedCPT: number;
  totalCampaignLevelFixedCost?: number;
  mediaOceanReferences: MediaOceanReferences;
  campaignStatusText: string;
  campaignCreator: string;
  campaignCreationDate: string;
  campaignModifiedDate: string;
  campaignFileDetails: Array<FileDetail>;
  campaignInventorySchedules: Array<CampaignFrameSchedule>;
  campaignPackSchedules: Array<CampaignFrameSchedule>;
  campaignContainerSchedules: Array<CampaignFrameSchedule>;
  campaignFreeFormContainerSchedules: Array<CampaignFrameSchedule>;
  allowedMediaOrganisations: Array<string>;
  campaignChartPresenters?: Array<CampaignChartPresenter>;
  campaignNonMediaCosts: Array<CampaignNonMediaCost>;
  campaignOptionDetails?: any;
  creativeAgencies: Array<any>;
  campaignLinePresenters: Array<CampaignLinePresenter>;
  isReadyForClients?: boolean;
  region?: string;
  isLocked?: boolean;
}

export class CampaignHeaders extends BaseCampaign {}
